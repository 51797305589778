import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Git`}</h1>
    <h2>{`Formatted logging`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`git log --pretty='%Cred%h%Creset | %C(yellow)%d%Creset %s %Cgreen(%cr)%Creset %C(cyan)[%an]%Creset' --graph --all
`}</code></pre>
    <p>{`Will output beautifly formatted git log.
Since it's a quite long command it would make sense to make a alias for that.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "980px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.51020408163265%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABmElEQVQoz4WT/ZKjIBDE8xh3q4CKIiCg4lfy/g/W23GTq927pO6PrtEq+NnTM16yEBiVwrxk5Dlhmj2WjZXvMWekZYYdOrSuQWVrCNtC6AZCVRAVJSUkGWelLj1hTkh4bzG4FnHoEUNAigljjAjBo/ctjO9Q9RqyU5ANQXX9pb+BU1ki8kvbccWSF9YVw2ThkoOdRijbQZoSUgsUuj2dyVKckKee0BM4P4B523C9HkhTQO1/ofIFfluDjyGi1IQWhPLs86L4ph8O78DxBK4EXpGZmR9bdEGhCRYiOAjDVutHZi9y+64/Dhc63NYV+75j2zf41MNNmm6ZUzAQztCp/ic38RZI0O12w7ouhBmYqKCcQjkYFLZH2ejT3bvsfgATJ53p7u7w2A9sdJvXCcPYo4sNW29Ph6Lvvlbm3jbvvGr7ku9AHpgJux4HDipOng4rSkOHCEVJTceqYMuPlXkLLAokPmwcyLyMsKkhhAd9gzI6fBhm19CVVBBC/r/l1HKZey5zSsj8M0IaMK1cHUOArlF23ctBvJv0J1mpOQMmKE+5AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Example of git ls",
          "title": "Example of git ls",
          "src": "/static/d742ecb717ac739056170b5c0b889c51/d30ee/git-ls.png",
          "srcSet": ["/static/d742ecb717ac739056170b5c0b889c51/86a2e/git-ls.png 245w", "/static/d742ecb717ac739056170b5c0b889c51/41d3c/git-ls.png 490w", "/static/d742ecb717ac739056170b5c0b889c51/d30ee/git-ls.png 980w", "/static/d742ecb717ac739056170b5c0b889c51/0b6f4/git-ls.png 984w"],
          "sizes": "(max-width: 980px) 100vw, 980px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2>{`Navigate to project root directory`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`cd \`git rev-parse --show-toplevel\`
`}</code></pre>
    <p>{`Defined alias `}<em parentName="p">{`cg`}</em>{` for that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      